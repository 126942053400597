import { TableColumn } from 'layout/TableLayout/types.d';
import dayjs from 'dayjs';
import { Status } from 'components/common';
import { ASSESSMENT_STATUS_COLOR } from 'constants/assessment';
import { CompanyTrial } from 'pages/Company/View/tabs/AssessmentList/index';
import { Trial_Status } from '@generated/graphql';
import DownloadReportsAction from './components/DownloadReportsAction';

const ASSESSMENTS_LIST_COLUMNS: TableColumn<CompanyTrial>[] = [
  {
    title: 'Дата создания',
    name: 'createdAt',
    render: ({ createdAt }) => dayjs(createdAt).format('DD.MM.YYYY'),
  },
  {
    title: 'Карта компетенций',
    name: 'competencyMap',
    render: ({ examination }) => examination.competencyMap.title,
  },
  {
    title: 'ФИО кандидата',
    name: 'candidate.fullName',
    render: ({ companyCandidate }) => companyCandidate.fullName,
  },
  {
    title: 'Ник кандидата',
    name: 'telegramUsername',
    render: ({ companyCandidate }) => companyCandidate.candidateUsername?.telegramUsername,
  },
  {
    title: 'Статус',
    name: 'status',
    render: ({ status }) => {
      return <Status<Trial_Status> status={status} statusDetails={ASSESSMENT_STATUS_COLOR} />;
    },
  },
  {
    title: 'Отчет',
    name: 'reports',
    render: (trial) => <DownloadReportsAction trial={trial} />,
  },
];

export default ASSESSMENTS_LIST_COLUMNS;
