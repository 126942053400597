import { ApolloError, gql, useApolloClient } from '@apollo/client';
import {
  useGenerateReportFileMutation,
  TrialFile,
  Trial_File_Type,
  Language,
} from '@generated/graphql';
import { useToast } from 'context/toastContext';

const useGenerateTrialFile = (getExistingFiles?: () => TrialFile[] | null | undefined) => {
  const { showErrorToast } = useToast();
  const client = useApolloClient();
  const [generateFile, { loading }] = useGenerateReportFileMutation();

  const onError = () => showErrorToast('Ошибка при генерации отчета');

  const onGenerateTrialFile = async (language: Language, trialId: string) => {
    try {
      const res = await generateFile({
        variables: {
          input: {
            language,
            trialId,
            type: Trial_File_Type.RecruiterReport,
          },
        },
      });

      const trialFile = res.data?.generateReportFile;

      if (!trialFile?.file?.url) {
        onError();
        return;
      }

      const existing = getExistingFiles?.() ?? [];

      client.cache.writeFragment({
        id: client.cache.identify({ __typename: 'Trial', id: trialId }),
        fragment: gql`
          fragment AddTrialFile on Trial {
            recruiterReportFile {
              id
              language
              type
              file {
                id
                url
                __typename
              }
              __typename
            }
          }
        `,
        data: {
          recruiterReportFile: [
            ...existing,
            {
              __typename: 'TrialFile',
              id: trialFile.id,
              language: trialFile.language,
              type: Trial_File_Type.RecruiterReport,
              file: {
                __typename: 'File',
                id: trialFile.file.id,
                url: trialFile.file.url,
              },
            },
          ],
        },
      });

      window.open(trialFile.file.url);
    } catch (e) {
      const error = e as ApolloError;
      console.error(
        error?.graphQLErrors?.[0]?.extensions?.originalError ||
          error?.message ||
          'Неизвестная ошибка',
      );
      onError();
    }
  };

  return { onGenerateTrialFile, loading };
};

export default useGenerateTrialFile;
