import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date custom scalar type */
  Date: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type AuthTokensDto = {
  __typename?: 'AuthTokensDto';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum Company_Status {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Created = 'CREATED',
  NotApproved = 'NOT_APPROVED',
  PartlyApproved = 'PARTLY_APPROVED',
}

export enum Competency_Map_Status {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Generating = 'GENERATING',
}

export type Candidate = {
  __typename?: 'Candidate';
  /** Согласие кандидата с офертой и обработкой его данных */
  agreement: Scalars['Boolean']['output'];
  /** Username кандидата в ТГ */
  candidateUsername: CandidateUsername;
  /** ID Username кандидата в ТГ */
  candidateUsernameId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  /** ФИО кандидата из ТГ */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Язык кандидата */
  language?: Maybe<Language>;
  /** Номер телефона кандидата */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type CandidateAWebDto = {
  __typename?: 'CandidateAWebDto';
  /** Согласие кандидата с офертой и обработкой его данных */
  agreement: Scalars['Boolean']['output'];
  /** Username и id кандидата в ТГ */
  candidateUsername: CandidateUsernameAWebDto;
  /** ID Username кандидата в ТГ */
  candidateUsernameId: Scalars['ID']['output'];
  /** ФИО кандидата из ТГ */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Язык кандидата */
  language?: Maybe<Language>;
  /** Номер телефона кандидата */
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type CandidateUsername = {
  __typename?: 'CandidateUsername';
  /** Кандидат */
  candidate?: Maybe<Candidate>;
  /** Связь кандидата с компанией */
  companyCandidate?: Maybe<Array<CompanyCandidate>>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Временный логин */
  login?: Maybe<Scalars['String']['output']>;
  /** ID пользователя из telegram */
  telegramId?: Maybe<Scalars['Float']['output']>;
  /** Профиль телеграм кандидата */
  telegramUsername?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type CandidateUsernameAWebDto = {
  __typename?: 'CandidateUsernameAWebDto';
  /** Кандидат */
  candidate?: Maybe<CandidateAWebDto>;
  id: Scalars['ID']['output'];
  /** Временный логин */
  login?: Maybe<Scalars['String']['output']>;
  /** ID пользователя из telegram */
  telegramId?: Maybe<Scalars['Float']['output']>;
  /** Профиль телеграм кандидата */
  telegramUsername?: Maybe<Scalars['String']['output']>;
};

export type Company = {
  __typename?: 'Company';
  /** Дата последних изменений */
  actualityDate?: Maybe<Scalars['Float']['output']>;
  /** Блоки знаний */
  companyKnowledgeBlocks?: Maybe<Array<KnowledgeBlock>>;
  /** История изменения статуса */
  companyStatusHistory?: Maybe<Array<CompanyStatusHistory>>;
  /** Пользователи компании */
  companyUsers?: Maybe<Array<CompanyUser>>;
  /** Карты компетенций компании */
  competencyMaps?: Maybe<Array<CompetencyMap>>;
  createdAt: Scalars['Date']['output'];
  /** Создатель компании */
  creatorUser?: Maybe<CompanyUser>;
  /** Полное наименование */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Идентификатор компании */
  identification: Scalars['String']['output'];
  /** ИНН */
  inn: Scalars['String']['output'];
  /** Флаг суперкомпании */
  isSupercompany: Scalars['Boolean']['output'];
  /** КПП */
  kpp: Scalars['String']['output'];
  /** Дата ликвидации */
  liquidationDate?: Maybe<Scalars['Float']['output']>;
  /** ФИО руководителя */
  managerFio: Scalars['String']['output'];
  /** Должность руководителя */
  managerPosition: Scalars['String']['output'];
  /** ОГРН */
  ogrn: Scalars['String']['output'];
  /** Дата выдачи ОГРН */
  ogrnDate: Scalars['Float']['output'];
  /** Код ОКАТО */
  okato: Scalars['String']['output'];
  /** Код ОКПО */
  okpo: Scalars['String']['output'];
  /** Код ОКТМО */
  oktmo: Scalars['String']['output'];
  /** Адрес регистрации */
  registrationAddress: Scalars['String']['output'];
  /** Дата регистрации */
  registrationDate: Scalars['Float']['output'];
  /** Краткое наименование */
  shortName: Scalars['String']['output'];
  /** Cтатус организации */
  state: Organization_State;
  /** Статус компании в системе */
  status: Company_Status;
  /** Тип организации */
  type: Type_Of_Organization;
  updatedAt: Scalars['Date']['output'];
};

export type CompanyAWebDto = {
  __typename?: 'CompanyAWebDto';
  /** Дата последних изменений */
  actualityDate?: Maybe<Scalars['Float']['output']>;
  /** Полное наименование */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Идентификатор компании */
  identification: Scalars['String']['output'];
  /** ИНН */
  inn: Scalars['String']['output'];
  /** Флаг суперкомпании */
  isSupercompany: Scalars['Boolean']['output'];
  /** КПП */
  kpp: Scalars['String']['output'];
  /** Дата ликвидации */
  liquidationDate?: Maybe<Scalars['Float']['output']>;
  /** ФИО руководителя */
  managerFio: Scalars['String']['output'];
  /** Должность руководителя */
  managerPosition: Scalars['String']['output'];
  /** ОГРН */
  ogrn: Scalars['String']['output'];
  /** Дата выдачи ОГРН */
  ogrnDate: Scalars['Float']['output'];
  /** Код ОКАТО */
  okato: Scalars['String']['output'];
  /** Код ОКПО */
  okpo: Scalars['String']['output'];
  /** Код ОКТМО */
  oktmo: Scalars['String']['output'];
  /** Адрес регистрации */
  registrationAddress: Scalars['String']['output'];
  /** Дата регистрации */
  registrationDate: Scalars['Float']['output'];
  /** Краткое наименование */
  shortName: Scalars['String']['output'];
  /** Cтатус организации */
  state: Organization_State;
  /** Статус компании в системе */
  status: Company_Status;
  /** Тип организации */
  type: Type_Of_Organization;
};

export type CompanyCandidate = {
  __typename?: 'CompanyCandidate';
  /** Username кандидата в ТГ */
  candidateUsername?: Maybe<CandidateUsername>;
  /** ID Username кандидата в ТГ */
  candidateUsernameId?: Maybe<Scalars['ID']['output']>;
  /** Компания пользователя */
  company: Company;
  /** ID компании пользователя */
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  /** Email кандидата */
  email?: Maybe<Scalars['String']['output']>;
  /** ФИО кандидата как его заполнила компания */
  fullName: Scalars['String']['output'];
  /** Грейд кандидата */
  grade?: Maybe<Grade>;
  id: Scalars['ID']['output'];
  /** Номер кандидата компании */
  numericId: Scalars['Float']['output'];
  /** Организация */
  organization?: Maybe<Scalars['String']['output']>;
  /** Позиция кандидата в компании */
  position?: Maybe<Scalars['String']['output']>;
  /** Тестирования кандидата */
  trials?: Maybe<Array<Trial>>;
  updatedAt: Scalars['Date']['output'];
};

export type CompanyCandidateAWebDto = {
  __typename?: 'CompanyCandidateAWebDto';
  /** Username и id кандидата в ТГ */
  candidateUsername?: Maybe<CandidateUsernameAWebDto>;
  /** ID Username кандидата в ТГ */
  candidateUsernameId?: Maybe<Scalars['ID']['output']>;
  /** Компания */
  company: CompanyAWebDto;
  /** ID компании пользователя */
  companyId: Scalars['ID']['output'];
  /** Email кандидата */
  email?: Maybe<Scalars['String']['output']>;
  /** ФИО кандидата как его заполнила компания */
  fullName: Scalars['String']['output'];
  /** Грейд кандидата */
  grade?: Maybe<Grade>;
  id: Scalars['ID']['output'];
  /** Номер кандидата компании */
  numericId: Scalars['Float']['output'];
  /** Организация */
  organization?: Maybe<Scalars['String']['output']>;
  /** Позиция кандидата в компании */
  position?: Maybe<Scalars['String']['output']>;
};

export type CompanyStatusHistory = {
  __typename?: 'CompanyStatusHistory';
  /** Компания пользователя */
  company: Company;
  /** ID компании пользователя */
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Новый статус компании */
  newStatus: Company_Status;
  updatedAt: Scalars['Date']['output'];
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  /** Компания пользователя */
  company: Company;
  /** ID компании пользователя */
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Флаг подтверждения */
  isConfirmed: Scalars['Boolean']['output'];
  /** Является ли создателем компании */
  isCreator: Scalars['Boolean']['output'];
  /** Является ли руководителем */
  isSupervisor: Scalars['Boolean']['output'];
  /** Должность */
  position: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Email пользователя */
  userEmail: UserEmail;
  /** ID email пользователя */
  userEmailId: Scalars['ID']['output'];
};

export type Competence = {
  __typename?: 'Competence';
  /** Карта компетенции */
  competencyMap: CompetencyMap;
  /** ID карты компетенции */
  competencyMapId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Компетенции блока */
  knowledgeBlockCompetences?: Maybe<Array<KnowledgeBlockCompetence>>;
  /** Порядок компетенции в карте компетенций */
  order: Scalars['Int']['output'];
  /** Название компетенции */
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type CompetenceAWebDto = {
  __typename?: 'CompetenceAWebDto';
  /** Карта компетенций */
  competencyMap: CompetencyMapAWebDto;
  /** ID карты компетенции */
  competencyMapId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Компетенции блока */
  knowledgeBlockCompetences?: Maybe<Array<KnowledgeBlockCompetenceAWebDto>>;
  /** Порядок компетенции в карте компетенций */
  order: Scalars['Int']['output'];
  /** Название компетенции */
  title: Scalars['String']['output'];
};

export type CompetencyMap = {
  __typename?: 'CompetencyMap';
  /** Комментарий к карте компетенций */
  comment?: Maybe<Scalars['String']['output']>;
  /** Компания */
  company: Company;
  /** ID компании */
  companyId: Scalars['ID']['output'];
  /** Компетенции карты компетенций */
  competences?: Maybe<Array<Competence>>;
  /** Количество компетенций в карте компетенций */
  competencesCount: Scalars['Float']['output'];
  /** Скопированные карты компетенций */
  copies?: Maybe<Array<CompetencyMap>>;
  createdAt: Scalars['Date']['output'];
  /** Дата "мягкого" удаления */
  deletedAt?: Maybe<Scalars['Date']['output']>;
  demoCode?: Maybe<Scalars['String']['output']>;
  /** Тип тестирования */
  examinations?: Maybe<Array<Examination>>;
  /** Грейды карты компетенций */
  grades?: Maybe<Array<Grade>>;
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Использовать проверку репозитория */
  isRepositoryCheck: Scalars['Boolean']['output'];
  /** Флаг примера */
  isSample: Scalars['Boolean']['output'];
  /** Номер карты компетенций компании */
  numericId: Scalars['Float']['output'];
  /** Оригинальная карта компетенций */
  originalCompetencyMap?: Maybe<CompetencyMap>;
  /** ID оригинальной карты компетенции */
  originalCompetencyMapId?: Maybe<Scalars['ID']['output']>;
  /** Специализация карты компетенций */
  specialization: Scalars['String']['output'];
  /** Статус карты */
  status: Competency_Map_Status;
  /** Название карты компетенций */
  title: Scalars['String']['output'];
  /** Количество тестирований с данной картой компетенций */
  trialsCount: Scalars['Float']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Использовать карту компетенций в боте */
  useInBot: Scalars['Boolean']['output'];
};

export type CompetencyMapAWebDto = {
  __typename?: 'CompetencyMapAWebDto';
  /** Комментарий к карте компетенций */
  comment?: Maybe<Scalars['String']['output']>;
  /** Компания, которой принадлежит карта */
  company: CompanyAWebDto;
  /** ID компании */
  companyId: Scalars['ID']['output'];
  /** Компетенции карты компетенций */
  competences?: Maybe<Array<CompetenceAWebDto>>;
  id: Scalars['ID']['output'];
  /** Использовать проверку репозитория */
  isRepositoryCheck: Scalars['Boolean']['output'];
  /** Флаг примера */
  isSample: Scalars['Boolean']['output'];
  /** Номер карты компетенций компании */
  numericId: Scalars['Float']['output'];
  /** Специализация карты компетенций */
  specialization: Scalars['String']['output'];
  /** Статус карты */
  status: Competency_Map_Status;
  /** Название карты компетенций */
  title: Scalars['String']['output'];
};

export type CompetencyMapFromXlsxInput = {
  /** Файл с данными для заполнения */
  file: Scalars['Upload']['input'];
  /** Специализация карты компетенций */
  specialization: Scalars['String']['input'];
  /** Название карты компетенций */
  title: Scalars['String']['input'];
};

export type CopyCompetenceMapInputDto = {
  /** Id карты компетенций */
  competencyMapId: Scalars['ID']['input'];
  /** Id компании в которую копировать копировать */
  newCompanyId?: InputMaybe<Scalars['ID']['input']>;
  /** Название новой карты компетенций */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompetencyMapFromFileInput = {
  /** Файл с данными для заполнения */
  dataFile: Scalars['Upload']['input'];
  /** Специализация карты компетенций */
  specialization: Scalars['String']['input'];
  /** Название карты компетенции */
  title: Scalars['String']['input'];
};

export type CriterionAssessment = {
  __typename?: 'CriterionAssessment';
  criteria: Scalars['String']['output'];
  decision: Scalars['String']['output'];
  explanation: Scalars['String']['output'];
};

export enum Examination_Type {
  Group = 'GROUP',
  Open = 'OPEN',
  Personal = 'PERSONAL',
}

export type Examination = {
  __typename?: 'Examination';
  /** Карта компетенций */
  competencyMap: CompetencyMap;
  /** Id карты компетенций */
  competencyMapId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Номер тестирования */
  numericId: Scalars['Float']['output'];
  /** Тестирования кандидатов */
  trials?: Maybe<Array<Trial>>;
  /** Тип тестирования */
  type: Examination_Type;
  updatedAt: Scalars['Date']['output'];
};

export type ExaminationAWebDto = {
  __typename?: 'ExaminationAWebDto';
  /** Карта компетенций */
  competencyMap: CompetencyMapAWebDto;
  /** Id карты компетенций */
  competencyMapId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Номер тестирования */
  numericId: Scalars['Float']['output'];
  /** Тестирования кандидатов */
  trials?: Maybe<Array<TrialAWebDto>>;
};

export type File = {
  __typename?: 'File';
  /** Ответы кандидата по тестированию */
  answers?: Maybe<Array<TrialAnswer>>;
  createdAt: Scalars['Date']['output'];
  /** Расширение файла */
  extension: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Ключ файла в S3 */
  key: Scalars['String']['output'];
  /** Название файла */
  name: Scalars['String']['output'];
  /** Отчеты по тестированию */
  trialFiles?: Maybe<Array<TrialFile>>;
  updatedAt: Scalars['Date']['output'];
  /** Ссылка на файл */
  url: Scalars['String']['output'];
};

export type FileAWebDto = {
  __typename?: 'FileAWebDto';
  /** Расширение файла */
  extension: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Ключ файла в S3 */
  key: Scalars['String']['output'];
  /** Название файла */
  name: Scalars['String']['output'];
  /** Ссылка на файл */
  url: Scalars['String']['output'];
};

export enum Grade {
  Junior = 'Junior',
  Middle = 'Middle',
  Senior = 'Senior',
}

export type GenerateReportFileInput = {
  /** Язык отчета */
  language: Language;
  /** Id тестирования */
  trialId: Scalars['ID']['input'];
  /** Тип отчета */
  type: Trial_File_Type;
};

export type KnowledgeBlock = {
  __typename?: 'KnowledgeBlock';
  /** Компания */
  company: Company;
  /** ID компании */
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Компетенции Блока */
  knowledgeBlockCompetences?: Maybe<Array<KnowledgeBlockCompetence>>;
  /** Профессия */
  profession: Scalars['String']['output'];
  /** Навыки компетенции */
  skills?: Maybe<Array<Skill>>;
  /** Название блока знаний */
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type KnowledgeBlockAWebDto = {
  __typename?: 'KnowledgeBlockAWebDto';
  id: Scalars['ID']['output'];
  /** Профессия */
  profession: Scalars['String']['output'];
  /** Название блока знаний */
  title: Scalars['String']['output'];
};

export type KnowledgeBlockCompetence = {
  __typename?: 'KnowledgeBlockCompetence';
  /** Компетенция */
  competence: Competence;
  /** ID Компетенции */
  competenceId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Блоки знаний */
  knowledgeBlock: KnowledgeBlock;
  /** ID Блока знаний */
  knowledgeBlockId: Scalars['ID']['output'];
  /** Порядок компетенции в блоке */
  order: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type KnowledgeBlockCompetenceAWebDto = {
  __typename?: 'KnowledgeBlockCompetenceAWebDto';
  /** Компетенция */
  competence: CompetenceAWebDto;
  /** ID Компетенции */
  competenceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Блок знаний */
  knowledgeBlock: KnowledgeBlockAWebDto;
  /** ID Блока знаний */
  knowledgeBlockId: Scalars['ID']['output'];
};

export enum Language {
  En = 'en',
  Kk = 'kk',
  Ru = 'ru',
}

export type ListCompanyFilterInput = {
  /** Дата создания карты компетенций, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** ФИО создателя компании */
  creatorFullName?: InputMaybe<Scalars['String']['input']>;
  /** ИНН */
  inn?: InputMaybe<Scalars['String']['input']>;
  /** ФИО руководителя */
  managerFio?: InputMaybe<Scalars['String']['input']>;
  /** Краткое наименование */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** Статус компании в системе */
  status?: InputMaybe<Company_Status>;
};

export type ListCompanyUserFilterInput = {
  /** Id компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Дата создания пользователя компании */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Email сотрудника компании */
  email?: InputMaybe<Scalars['String']['input']>;
  /** ИНН */
  inn?: InputMaybe<Scalars['String']['input']>;
  /** Является ли создателем компании */
  isCreator?: InputMaybe<Scalars['Boolean']['input']>;
  /** Является ли руководителем */
  isSupervisor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Должность руководителя */
  position?: InputMaybe<Scalars['String']['input']>;
  /** Краткое наименование */
  shortCompanyName?: InputMaybe<Scalars['String']['input']>;
  /** ФИО сотрудника компании */
  userFullName?: InputMaybe<Scalars['String']['input']>;
  /** Id пользователя */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ListCompetencyMapFilterInput = {
  /** Id компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Дата создания карты компетенций, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Название карты компетенций */
  fullCompanyName?: InputMaybe<Scalars['String']['input']>;
  /** Грейды */
  grades?: InputMaybe<Array<Grade>>;
  /** Статус карты */
  status?: InputMaybe<Competency_Map_Status>;
  /** Название карты компетенций */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ListTrialFilterInput = {
  /** ФИО кандидата */
  candidateFullName?: InputMaybe<Scalars['String']['input']>;
  /** companyId компании */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Название карты компетенций */
  competencyMapTitle?: InputMaybe<Scalars['String']['input']>;
  /** Дата создания тестирования, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createAt?: InputMaybe<Scalars['Date']['input']>;
  /** Статус тестирования */
  status?: InputMaybe<Trial_Status>;
  /** Профиль телеграм кандидата */
  telegramUsername?: InputMaybe<Scalars['String']['input']>;
};

export type ListUsersFilterInput = {
  /** Дата создания компетенци, отправлять нужное число в локальном часовом поясе c временем 00:00:000 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  /** ФИО сотрудника компании */
  userFullName?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Блокировка или разблокировка пользователя */
  blockUnblockUser: User;
  /** Создание новой карты компетенций на основе другой */
  copyCompetencyMap: CompetencyMap;
  /** Загрузка карты компетенции из файла .json */
  createCompetencyMapFromFile: CompetencyMap;
  /** Загрузка карты компетенции из файла xlsx c инициализацией генерации вопросов */
  createCompetencyMapFromXlsx: CompetencyMap;
  /** Полное или "мягкое" удаление карты компетенций */
  deleteCompetencyMap: Scalars['Boolean']['output'];
  /** Файл отчета */
  generateReportFile: TrialFile;
  /** Авторизация связке номер email + пароль */
  loginByCredentials: AuthTokensDto;
  /** Обновление токенов авторизации */
  refreshTokens: AuthTokensDto;
  /** Восстановление карты компетенций */
  restoreCompetencyMap: Scalars['Boolean']['output'];
  /** Изменение статуса компании в системе */
  setStatusCompany: Company;
  /** Изменение поля isSample карты компетенции на противоположное */
  switchIsSampleCompetencyMap: CompetencyMap;
  systemCopyCompetencyMaps?: Maybe<Array<Scalars['ID']['output']>>;
  systemReinitAnswerAssessment: Array<Scalars['String']['output']>;
  systemSetPassword: Scalars['String']['output'];
  /** Обновление компании */
  updateCompany: Company;
  /** Обновление данных пользователя */
  updateUser: User;
};

export type MutationBlockUnblockUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationCopyCompetencyMapArgs = {
  input: CopyCompetenceMapInputDto;
};

export type MutationCreateCompetencyMapFromFileArgs = {
  companyId: Scalars['ID']['input'];
  input: CreateCompetencyMapFromFileInput;
};

export type MutationCreateCompetencyMapFromXlsxArgs = {
  companyId: Scalars['ID']['input'];
  input: CompetencyMapFromXlsxInput;
};

export type MutationDeleteCompetencyMapArgs = {
  competencyMapId: Scalars['ID']['input'];
  isHardDelete?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationGenerateReportFileArgs = {
  input: GenerateReportFileInput;
};

export type MutationLoginByCredentialsArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationRefreshTokensArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationRestoreCompetencyMapArgs = {
  competencyMapId: Scalars['ID']['input'];
};

export type MutationSetStatusCompanyArgs = {
  input: SetCompanyStatusInput;
};

export type MutationSwitchIsSampleCompetencyMapArgs = {
  competencyMapId: Scalars['ID']['input'];
};

export type MutationSystemCopyCompetencyMapsArgs = {
  cmIds: Array<Scalars['ID']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  fullCopy: Scalars['Boolean']['input'];
  link: Scalars['Boolean']['input'];
  postfix?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSystemReinitAnswerAssessmentArgs = {
  answersIds: Array<Scalars['ID']['input']>;
};

export type MutationSystemSetPasswordArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export enum Organization_State {
  Active = 'ACTIVE',
  Bankrupt = 'BANKRUPT',
  Liquidated = 'LIQUIDATED',
  Liquidating = 'LIQUIDATING',
  Reorganizing = 'REORGANIZING',
}

export type PaginatedCompany = {
  __typename?: 'PaginatedCompany';
  /** Данные на указанной странице */
  data?: Maybe<Array<Company>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedCompanyUser = {
  __typename?: 'PaginatedCompanyUser';
  /** Данные на указанной странице */
  data?: Maybe<Array<CompanyUser>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedCompetencyMap = {
  __typename?: 'PaginatedCompetencyMap';
  /** Данные на указанной странице */
  data?: Maybe<Array<CompetencyMap>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedTrial = {
  __typename?: 'PaginatedTrial';
  /** Данные на указанной странице */
  data?: Maybe<Array<Trial>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  /** Данные на указанной странице */
  data?: Maybe<Array<User>>;
  /** Есть ли следующая страница */
  hasNextPage: Scalars['Boolean']['output'];
  /** Есть ли предыдущая странца */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Номер страницы (отсчёт с 0) */
  limit: Scalars['Float']['output'];
  /** Общее число записей */
  page: Scalars['Float']['output'];
  /** Ограничение количества записей на странице */
  totalCount: Scalars['Float']['output'];
};

export enum Question_Type {
  Practical = 'practical',
  Theoretical = 'theoretical',
}

export type Query = {
  __typename?: 'Query';
  /** Список компаний системы */
  companies?: Maybe<PaginatedCompany>;
  /** Компания по Id */
  company?: Maybe<Company>;
  /** Сотрудник компании по Id */
  companyUser?: Maybe<CompanyUser>;
  /** Список компаний системы */
  companyUsers?: Maybe<PaginatedCompanyUser>;
  /** Карта компетенций по id */
  competencyMap: CompetencyMap;
  /** Список карт компетенций */
  competencyMaps: PaginatedCompetencyMap;
  /** Текущий пользователь */
  me: User;
  /** Список тестирований */
  trials: PaginatedTrial;
  /** Пользователь по Id */
  user?: Maybe<User>;
  /** Список пользователей */
  users: PaginatedUser;
};

export type QueryCompaniesArgs = {
  filter?: InputMaybe<ListCompanyFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryCompanyArgs = {
  companyId: Scalars['ID']['input'];
};

export type QueryCompanyUserArgs = {
  companyUserId: Scalars['ID']['input'];
};

export type QueryCompanyUsersArgs = {
  filter?: InputMaybe<ListCompanyUserFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryCompetencyMapArgs = {
  competencyMapId: Scalars['ID']['input'];
};

export type QueryCompetencyMapsArgs = {
  filter?: InputMaybe<ListCompetencyMapFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryTrialsArgs = {
  filter?: InputMaybe<ListTrialFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type QueryUserArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  filter: ListUsersFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
};

export type Question = {
  __typename?: 'Question';
  /** Ответы кандидата по тестированию */
  answers?: Maybe<Array<TrialAnswer>>;
  /** Подтверждён ли вопрос */
  confirmation: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  /** Критерии вопроса */
  criterion: Array<Scalars['String']['output']>;
  /** Грейд вопроса */
  grade: Grade;
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Язык вопроса */
  language: Language;
  /** Микрокритерии вопроса */
  microCriterion?: Maybe<Array<Scalars['String']['output']>>;
  /** Рекомендуемое количество секунд отведённых на вопрос */
  recommendedTime?: Maybe<Scalars['Float']['output']>;
  /** Навык */
  skill: Skill;
  /** ID топика */
  skillId: Scalars['ID']['output'];
  /** Подтип вопроса */
  subtype?: Maybe<Scalars['String']['output']>;
  /** Текст вопроса */
  text: Scalars['String']['output'];
  /** Количество секунд отведённых на вопрос */
  time: Scalars['Float']['output'];
  /** Тип вопроса */
  type: Question_Type;
  updatedAt: Scalars['Date']['output'];
};

export type QuestionAWebDto = {
  __typename?: 'QuestionAWebDto';
  /** Подтверждён ли вопрос */
  confirmation: Scalars['Boolean']['output'];
  /** Критерии вопроса */
  criterion: Array<Scalars['String']['output']>;
  /** Грейд вопроса */
  grade: Grade;
  id: Scalars['ID']['output'];
  /** Язык вопроса */
  language: Language;
  /** Рекомендуемое количество секунд отведённых на вопрос */
  recommendedTime?: Maybe<Scalars['Float']['output']>;
  /** Топик */
  skill: SkillAWebDto;
  /** ID топика */
  skillId: Scalars['ID']['output'];
  /** Текст вопроса */
  text: Scalars['String']['output'];
  /** Количество секунд отведённых на вопрос */
  time: Scalars['Float']['output'];
  /** Тип вопроса */
  type: Question_Type;
};

export type QuestionForTrialInfoAWebDto = {
  __typename?: 'QuestionForTrialInfoAWebDto';
  id: Scalars['ID']['output'];
  /** Порядковый номер в тестировании */
  order: Scalars['Float']['output'];
  /** Количество секунд отведённых на вопрос */
  time: Scalars['Float']['output'];
};

export type SetCompanyStatusInput = {
  companyId: Scalars['ID']['input'];
  newStatus: Company_Status;
};

export type Skill = {
  __typename?: 'Skill';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Флаг удаления */
  isDeleted: Scalars['Boolean']['output'];
  /** Блоки знаний */
  knowledgeBlock: KnowledgeBlock;
  /** ID Блока знаний */
  knowledgeBlockId: Scalars['ID']['output'];
  /** Порядок топика в компетенции */
  order: Scalars['Int']['output'];
  /** Вопросы навыка */
  questions?: Maybe<Array<Question>>;
  /** Название топика */
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type SkillAWebDto = {
  __typename?: 'SkillAWebDto';
  id: Scalars['ID']['output'];
  /** Блок знаний */
  knowledgeBlock: KnowledgeBlockAWebDto;
  /** ID Блока знаний */
  knowledgeBlockId: Scalars['ID']['output'];
  /** Порядок топика в компетенции */
  order: Scalars['Int']['output'];
  /** Название топика */
  title: Scalars['String']['output'];
};

export enum Trial_File_Type {
  CandidateReport = 'CANDIDATE_REPORT',
  RecruiterReport = 'RECRUITER_REPORT',
}

export enum Trial_Status {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Received = 'RECEIVED',
  Started = 'STARTED',
}

export enum Type_Of_Organization {
  Individual = 'INDIVIDUAL',
  Legal = 'LEGAL',
}

export type Trial = {
  __typename?: 'Trial';
  /** ID чата с ботом в telegram */
  botChatId?: Maybe<Scalars['Float']['output']>;
  /** Комментарий к тестированию */
  comment?: Maybe<Scalars['String']['output']>;
  /** Кандидат компании */
  companyCandidate: CompanyCandidate;
  /** ID Кандидата компании */
  companyCandidateId: Scalars['ID']['output'];
  /** Дата завершения тестирования */
  completedAt?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Тип тестирования */
  examination: Examination;
  /** Id типа тестирования */
  examinationId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Завершено ли тестирование */
  isAssessmentComplete: Scalars['Boolean']['output'];
  /** Числовой id тестирования */
  numericId: Scalars['Float']['output'];
  /** Генерировать вопросы картинкой */
  questionsAsImage: Scalars['Boolean']['output'];
  /** Отчет рекрутера */
  recruiterReportFiles?: Maybe<Array<TrialFile>>;
  /** Статус тестирования */
  status: Trial_Status;
  /** Ответы кандидата по тестированию */
  trialAnswers?: Maybe<Array<TrialAnswer>>;
  /** Отчеты по тестированию */
  trialFiles?: Maybe<Array<TrialFile>>;
  /** Результаты проверки репозитория */
  trialRepositoryChecks: Array<TrialRepositoryCheck>;
  /** История статусов тестирования */
  trialStatusHistory?: Maybe<Array<TrialStatusHistory>>;
  updatedAt: Scalars['Date']['output'];
};

export type TrialAWebDto = {
  __typename?: 'TrialAWebDto';
  /** Комментарий к тестированию */
  comment?: Maybe<Scalars['String']['output']>;
  /** Кандидат */
  companyCandidate: CompanyCandidateAWebDto;
  /** ID Кандидата компании */
  companyCandidateId: Scalars['ID']['output'];
  /** Дата завершения тестирования */
  completedAt?: Maybe<Scalars['Date']['output']>;
  /** Карта компетенций */
  examination: ExaminationAWebDto;
  /** Id типа тестирования */
  examinationId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Числовой id тестирования */
  numericId: Scalars['Float']['output'];
  /** Генерировать вопросы картинкой */
  questionsAsImage: Scalars['Boolean']['output'];
  /** Статус тестирования */
  status: Trial_Status;
  /** Ответы */
  trialAnswers?: Maybe<Array<TrialAnswerAWebDto>>;
  /** Результаты проверки репозитория */
  trialRepositoryChecks: Array<TrialRepositoryCheckAWebDto>;
};

export type TrialAnswer = {
  __typename?: 'TrialAnswer';
  /** Ответ на вопрос */
  answer?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Вердикт ответа */
  criterionAssessments?: Maybe<Array<CriterionAssessment>>;
  /** Файл голосового ответа */
  file?: Maybe<File>;
  /** ID файла голосового ответа */
  fileId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** Вердикт микрокритерия */
  microcriterionAssessments?: Maybe<Array<CriterionAssessment>>;
  /** Оценка микрокритерия */
  microcriterionScore?: Maybe<Scalars['Float']['output']>;
  /** Вопрос */
  question: Question;
  /** ID вопроса */
  questionId: Scalars['ID']['output'];
  /** Оценка ответа */
  score?: Maybe<Scalars['Float']['output']>;
  /** Количество секунд затраченных на ответ */
  time: Scalars['Float']['output'];
  /** Тестирование */
  trial: Trial;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type TrialAnswerAWebDto = {
  __typename?: 'TrialAnswerAWebDto';
  /** Ответ на вопрос */
  answer?: Maybe<Scalars['String']['output']>;
  /** Вердикт ответа */
  criterionAssessments?: Maybe<Array<CriterionAssessment>>;
  /** Файл аудио ответа */
  file?: Maybe<FileAWebDto>;
  /** ID файла голосового ответа */
  fileId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** Вердикт микрокритерия */
  microcriterionAssessments?: Maybe<Array<CriterionAssessment>>;
  /** Оценка микрокритерия */
  microcriterionScore?: Maybe<Scalars['Float']['output']>;
  /** Вопрос */
  question: QuestionAWebDto;
  /** ID вопроса */
  questionId: Scalars['ID']['output'];
  /** Оценка ответа */
  score?: Maybe<Scalars['Float']['output']>;
  /** Количество секунд затраченных на ответ */
  time: Scalars['Float']['output'];
  /** Тестирование */
  trial: TrialAWebDto;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
};

export type TrialFile = {
  __typename?: 'TrialFile';
  createdAt: Scalars['Date']['output'];
  /** Данные файла */
  file: File;
  /** ID файла */
  fileId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Язык отчета */
  language: Language;
  /** Тестирование */
  trial: Trial;
  /** ID тестирования */
  trialId: Scalars['ID']['output'];
  /** Тип отчета */
  type: Trial_File_Type;
  updatedAt: Scalars['Date']['output'];
};

export type TrialRepositoryCheck = {
  __typename?: 'TrialRepositoryCheck';
  /** Балл за алгоритмы */
  algorithmsScore?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Ошибка API проверки репозитория */
  error?: Maybe<Scalars['JSON']['output']>;
  /** Факты о репозитории */
  facts?: Maybe<TrialRepositoryFacts>;
  id: Scalars['ID']['output'];
  /** Балл за поддерживаемость */
  maintainabilityScore?: Maybe<Scalars['Float']['output']>;
  /** Основной язык репозитория */
  programingLanguage?: Maybe<Scalars['String']['output']>;
  /** Балл за читаемость */
  readabilityScore?: Maybe<Scalars['Float']['output']>;
  /** Ссылка на репозиторий */
  repoUrl?: Maybe<Scalars['String']['output']>;
  /** ID в API проверки репозитория */
  taskId?: Maybe<Scalars['ID']['output']>;
  /** Общий балл */
  totalScore?: Maybe<Scalars['Float']['output']>;
  /** Тестирование */
  trial: Trial;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type TrialRepositoryCheckAWebDto = {
  __typename?: 'TrialRepositoryCheckAWebDto';
  /** Балл за алгоритмы */
  algorithmsScore?: Maybe<Scalars['Float']['output']>;
  /** Ошибка API проверки репозитория */
  error?: Maybe<Scalars['JSON']['output']>;
  /** Факты о репозитории */
  facts?: Maybe<TrialRepositoryFacts>;
  /** Балл за поддерживаемость */
  maintainabilityScore?: Maybe<Scalars['Float']['output']>;
  /** Основной язык репозитория */
  programingLanguage?: Maybe<Scalars['String']['output']>;
  /** Балл за читаемость */
  readabilityScore?: Maybe<Scalars['Float']['output']>;
  /** Ссылка на репозиторий */
  repoUrl?: Maybe<Scalars['String']['output']>;
  /** Общий балл */
  totalScore?: Maybe<Scalars['Float']['output']>;
  /** Тестирование */
  trial: TrialAWebDto;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
};

export type TrialRepositoryFacts = {
  __typename?: 'TrialRepositoryFacts';
  developers_count?: Maybe<Scalars['Float']['output']>;
  first_release_date?: Maybe<Scalars['Date']['output']>;
  forks_count?: Maybe<Scalars['Float']['output']>;
  is_scoring_available?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Scalars['JSON']['output']>;
  main_language?: Maybe<Scalars['String']['output']>;
  repo_url?: Maybe<Scalars['String']['output']>;
  stars_count?: Maybe<Scalars['Float']['output']>;
};

export type TrialStatusHistory = {
  __typename?: 'TrialStatusHistory';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Новый статус тестирования */
  newStatus: Trial_Status;
  /** Тестирование */
  trial: Trial;
  /** ID Тестирования */
  trialId: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type UpdateCompanyInput = {
  /** Дата последних изменений */
  actualityDate?: InputMaybe<Scalars['Float']['input']>;
  /** Полное наименование */
  fullName: Scalars['String']['input'];
  /** Id компании */
  id: Scalars['String']['input'];
  /** ИНН */
  inn: Scalars['String']['input'];
  /** КПП */
  kpp: Scalars['String']['input'];
  /** Дата ликвидации */
  liquidationDate?: InputMaybe<Scalars['Float']['input']>;
  /** ФИО руководителя */
  managerFio: Scalars['String']['input'];
  /** Должность руководителя */
  managerPosition: Scalars['String']['input'];
  /** ОГРН */
  ogrn: Scalars['String']['input'];
  /** Дата выдачи ОГРН */
  ogrnDate: Scalars['Float']['input'];
  /** Код ОКАТО */
  okato: Scalars['String']['input'];
  /** Код ОКПО */
  okpo: Scalars['String']['input'];
  /** Код ОКТМО */
  oktmo: Scalars['String']['input'];
  /** Адрес регистрации */
  registrationAddress: Scalars['String']['input'];
  /** Дата регистрации */
  registrationDate: Scalars['Float']['input'];
  /** Краткое наименование */
  shortName: Scalars['String']['input'];
  /** Cтатус организации */
  state: Organization_State;
  /** Тип организации */
  type: Type_Of_Organization;
};

export type UpdateUserInput = {
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Id пользователя */
  id: Scalars['ID']['input'];
  /** Фамилия */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  middleName?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['Date']['output'];
  /** Имя пользователя */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Является ли пользователь админом */
  isAdmin: Scalars['Boolean']['output'];
  /** Заблокирован ли пользователь */
  isBlocked: Scalars['Boolean']['output'];
  /** Фамилия пользователя */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Отчество пользователя */
  middleName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  /** Eмail пользователя */
  userEmail: UserEmail;
  /** ID email пользователя */
  userEmailId: Scalars['ID']['output'];
};

export type UserEmail = {
  __typename?: 'UserEmail';
  /** Пользователи компании */
  companyUsers?: Maybe<Array<CompanyUser>>;
  createdAt: Scalars['Date']['output'];
  /** Email пользователя */
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
};

export type LoginByCredentialsMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginByCredentialsMutation = {
  __typename?: 'Mutation';
  loginByCredentials: { __typename?: 'AuthTokensDto'; accessToken: string; refreshToken: string };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
  };
};

export type GenerateReportFileMutationVariables = Exact<{
  input: GenerateReportFileInput;
}>;

export type GenerateReportFileMutation = {
  __typename?: 'Mutation';
  generateReportFile: {
    __typename?: 'TrialFile';
    id: string;
    language: Language;
    trial: {
      __typename?: 'Trial';
      id: string;
      examination: { __typename?: 'Examination'; id: string };
    };
    file: { __typename?: 'File'; id: string; url: string };
  };
};

export type SetStatusCompanyMutationVariables = Exact<{
  input: SetCompanyStatusInput;
}>;

export type SetStatusCompanyMutation = {
  __typename?: 'Mutation';
  setStatusCompany: { __typename?: 'Company'; id: string; status: Company_Status };
};

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany: {
    __typename?: 'Company';
    id: string;
    inn: string;
    fullName: string;
    shortName: string;
    kpp: string;
    ogrn: string;
    ogrnDate: number;
    type: Type_Of_Organization;
    managerFio: string;
    managerPosition: string;
    okpo: string;
    okato: string;
    oktmo: string;
    registrationAddress: string;
    state: Organization_State;
    registrationDate: number;
  };
};

export type CompaniesQueryVariables = Exact<{
  filter?: InputMaybe<ListCompanyFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type CompaniesQuery = {
  __typename?: 'Query';
  companies?: {
    __typename?: 'PaginatedCompany';
    totalCount: number;
    data?: Array<{
      __typename?: 'Company';
      id: string;
      createdAt: any;
      inn: string;
      shortName: string;
      status: Company_Status;
      creatorUser?: {
        __typename?: 'CompanyUser';
        id: string;
        userEmail: {
          __typename?: 'UserEmail';
          id: string;
          user: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            middleName?: string | null;
          };
        };
      } | null;
    }> | null;
  } | null;
};

export type CompanyCompetencyMapsQueryVariables = Exact<{
  filter?: InputMaybe<ListCompetencyMapFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type CompanyCompetencyMapsQuery = {
  __typename?: 'Query';
  competencyMaps: {
    __typename?: 'PaginatedCompetencyMap';
    totalCount: number;
    data?: Array<{
      __typename?: 'CompetencyMap';
      id: string;
      createdAt: any;
      isSample: boolean;
      title: string;
      specialization: string;
      grades?: Array<Grade> | null;
      competencesCount: number;
      trialsCount: number;
      deletedAt?: any | null;
      company: { __typename?: 'Company'; id: string; shortName: string };
    }> | null;
  };
};

export type CompanyDataQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type CompanyDataQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id: string;
    inn: string;
    fullName: string;
    shortName: string;
    kpp: string;
    ogrn: string;
    ogrnDate: number;
    type: Type_Of_Organization;
    managerFio: string;
    managerPosition: string;
    okpo: string;
    okato: string;
    oktmo: string;
    registrationAddress: string;
    state: Organization_State;
    registrationDate: number;
  } | null;
};

export type CompanyHeaderQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;

export type CompanyHeaderQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id: string;
    shortName: string;
    identification: string;
    status: Company_Status;
  } | null;
};

export type CompanyOptionsQueryVariables = Exact<{
  filter?: InputMaybe<ListCompanyFilterInput>;
  limit?: Scalars['Float']['input'];
  page?: Scalars['Float']['input'];
}>;

export type CompanyOptionsQuery = {
  __typename?: 'Query';
  companies?: {
    __typename?: 'PaginatedCompany';
    totalCount: number;
    data?: Array<{ __typename?: 'Company'; id: string; shortName: string; inn: string }> | null;
  } | null;
};

export type CompanyTrialsQueryVariables = Exact<{
  filter?: InputMaybe<ListTrialFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type CompanyTrialsQuery = {
  __typename?: 'Query';
  trials: {
    __typename?: 'PaginatedTrial';
    totalCount: number;
    data?: Array<{
      __typename?: 'Trial';
      id: string;
      createdAt: any;
      completedAt?: any | null;
      status: Trial_Status;
      examination: {
        __typename?: 'Examination';
        id: string;
        competencyMap: { __typename?: 'CompetencyMap'; id: string; title: string };
      };
      companyCandidate: {
        __typename?: 'CompanyCandidate';
        id: string;
        fullName: string;
        candidateUsername?: {
          __typename?: 'CandidateUsername';
          id: string;
          telegramUsername?: string | null;
        } | null;
      };
      recruiterReportFiles?: Array<{
        __typename?: 'TrialFile';
        id: string;
        language: Language;
        file: { __typename?: 'File'; id: string; url: string };
      }> | null;
    }> | null;
  };
};

export type CompanyUsersQueryVariables = Exact<{
  filter?: InputMaybe<ListCompanyUserFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type CompanyUsersQuery = {
  __typename?: 'Query';
  companyUsers?: {
    __typename?: 'PaginatedCompanyUser';
    totalCount: number;
    data?: Array<{
      __typename?: 'CompanyUser';
      id: string;
      position: string;
      isSupervisor: boolean;
      isCreator: boolean;
      userEmail: {
        __typename?: 'UserEmail';
        id: string;
        email: string;
        user: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          middleName?: string | null;
        };
      };
    }> | null;
  } | null;
};

export type CopyCompetencyMapMutationVariables = Exact<{
  input: CopyCompetenceMapInputDto;
}>;

export type CopyCompetencyMapMutation = {
  __typename?: 'Mutation';
  copyCompetencyMap: { __typename?: 'CompetencyMap'; id: string };
};

export type CreateCompetencyMapFromFileMutationVariables = Exact<{
  companyId: Scalars['ID']['input'];
  input: CreateCompetencyMapFromFileInput;
}>;

export type CreateCompetencyMapFromFileMutation = {
  __typename?: 'Mutation';
  createCompetencyMapFromFile: { __typename?: 'CompetencyMap'; id: string };
};

export type DeleteCompetencyMapMutationVariables = Exact<{
  competencyMapId: Scalars['ID']['input'];
}>;

export type DeleteCompetencyMapMutation = { __typename?: 'Mutation'; deleteCompetencyMap: boolean };

export type RestoreCompetencyMapMutationVariables = Exact<{
  competencyMapId: Scalars['ID']['input'];
}>;

export type RestoreCompetencyMapMutation = {
  __typename?: 'Mutation';
  restoreCompetencyMap: boolean;
};

export type SwitchIsSampleCompetencyMapMutationVariables = Exact<{
  competencyMapId: Scalars['ID']['input'];
}>;

export type SwitchIsSampleCompetencyMapMutation = {
  __typename?: 'Mutation';
  switchIsSampleCompetencyMap: { __typename?: 'CompetencyMap'; id: string; isSample: boolean };
};

export type CompetencyMapListQueryVariables = Exact<{
  filter?: InputMaybe<ListCompetencyMapFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type CompetencyMapListQuery = {
  __typename?: 'Query';
  competencyMaps: {
    __typename?: 'PaginatedCompetencyMap';
    totalCount: number;
    data?: Array<{
      __typename?: 'CompetencyMap';
      id: string;
      deletedAt?: any | null;
      createdAt: any;
      isSample: boolean;
      isDeleted: boolean;
      title: string;
      specialization: string;
      grades?: Array<Grade> | null;
      competencesCount: number;
      trialsCount: number;
      company: { __typename?: 'Company'; id: string; shortName: string };
    }> | null;
  };
};

export type BlockUnblockUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type BlockUnblockUserMutation = {
  __typename?: 'Mutation';
  blockUnblockUser: { __typename?: 'User'; isBlocked: boolean; id: string };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    userEmail: { __typename?: 'UserEmail'; id: string; email: string };
  };
};

export type UserCompaniesQueryVariables = Exact<{
  filter?: InputMaybe<ListCompanyUserFilterInput>;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type UserCompaniesQuery = {
  __typename?: 'Query';
  companyUsers?: {
    __typename?: 'PaginatedCompanyUser';
    totalCount: number;
    data?: Array<{
      __typename?: 'CompanyUser';
      id: string;
      createdAt: any;
      position: string;
      isCreator: boolean;
      isSupervisor: boolean;
      company: { __typename?: 'Company'; id: string; inn: string; shortName: string };
    }> | null;
  } | null;
};

export type UserDataQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserDataQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    userEmail: { __typename?: 'UserEmail'; id: string; email: string };
  } | null;
};

export type UserHeaderQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type UserHeaderQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    isBlocked: boolean;
  } | null;
};

export type UsersQueryVariables = Exact<{
  filter?: ListUsersFilterInput;
  limit: Scalars['Float']['input'];
  page: Scalars['Float']['input'];
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'PaginatedUser';
    totalCount: number;
    data?: Array<{
      __typename?: 'User';
      id: string;
      createdAt: any;
      firstName?: string | null;
      lastName?: string | null;
      middleName?: string | null;
      isBlocked: boolean;
      userEmail: { __typename?: 'UserEmail'; id: string; email: string };
    }> | null;
  };
};

export const LoginByCredentialsDocument = gql`
  mutation loginByCredentials($email: String!, $password: String!) {
    loginByCredentials(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;
export type LoginByCredentialsMutationFn = Apollo.MutationFunction<
  LoginByCredentialsMutation,
  LoginByCredentialsMutationVariables
>;

/**
 * __useLoginByCredentialsMutation__
 *
 * To run a mutation, you first call `useLoginByCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByCredentialsMutation, { data, loading, error }] = useLoginByCredentialsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginByCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginByCredentialsMutation,
    LoginByCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginByCredentialsMutation, LoginByCredentialsMutationVariables>(
    LoginByCredentialsDocument,
    options,
  );
}
export type LoginByCredentialsMutationHookResult = ReturnType<typeof useLoginByCredentialsMutation>;
export type LoginByCredentialsMutationResult = Apollo.MutationResult<LoginByCredentialsMutation>;
export type LoginByCredentialsMutationOptions = Apollo.BaseMutationOptions<
  LoginByCredentialsMutation,
  LoginByCredentialsMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      id
      firstName
      lastName
      middleName
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GenerateReportFileDocument = gql`
  mutation generateReportFile($input: GenerateReportFileInput!) {
    generateReportFile(input: $input) {
      id
      language
      trial {
        id
        examination {
          id
        }
      }
      file {
        id
        url
      }
    }
  }
`;
export type GenerateReportFileMutationFn = Apollo.MutationFunction<
  GenerateReportFileMutation,
  GenerateReportFileMutationVariables
>;

/**
 * __useGenerateReportFileMutation__
 *
 * To run a mutation, you first call `useGenerateReportFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportFileMutation, { data, loading, error }] = useGenerateReportFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReportFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateReportFileMutation,
    GenerateReportFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateReportFileMutation, GenerateReportFileMutationVariables>(
    GenerateReportFileDocument,
    options,
  );
}
export type GenerateReportFileMutationHookResult = ReturnType<typeof useGenerateReportFileMutation>;
export type GenerateReportFileMutationResult = Apollo.MutationResult<GenerateReportFileMutation>;
export type GenerateReportFileMutationOptions = Apollo.BaseMutationOptions<
  GenerateReportFileMutation,
  GenerateReportFileMutationVariables
>;
export const SetStatusCompanyDocument = gql`
  mutation setStatusCompany($input: SetCompanyStatusInput!) {
    setStatusCompany(input: $input) {
      id
      status
    }
  }
`;
export type SetStatusCompanyMutationFn = Apollo.MutationFunction<
  SetStatusCompanyMutation,
  SetStatusCompanyMutationVariables
>;

/**
 * __useSetStatusCompanyMutation__
 *
 * To run a mutation, you first call `useSetStatusCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStatusCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStatusCompanyMutation, { data, loading, error }] = useSetStatusCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStatusCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetStatusCompanyMutation,
    SetStatusCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetStatusCompanyMutation, SetStatusCompanyMutationVariables>(
    SetStatusCompanyDocument,
    options,
  );
}
export type SetStatusCompanyMutationHookResult = ReturnType<typeof useSetStatusCompanyMutation>;
export type SetStatusCompanyMutationResult = Apollo.MutationResult<SetStatusCompanyMutation>;
export type SetStatusCompanyMutationOptions = Apollo.BaseMutationOptions<
  SetStatusCompanyMutation,
  SetStatusCompanyMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      inn
      fullName
      shortName
      kpp
      ogrn
      ogrnDate
      type
      managerFio
      managerPosition
      okpo
      okato
      oktmo
      registrationAddress
      state
      registrationDate
    }
  }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UpdateCompanyDocument,
    options,
  );
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const CompaniesDocument = gql`
  query companies($filter: ListCompanyFilterInput = {}, $limit: Float!, $page: Float!) {
    companies(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        createdAt
        inn
        shortName
        status
        creatorUser {
          id
          userEmail {
            id
            user {
              id
              firstName
              lastName
              middleName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables> &
    ({ variables: CompaniesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
}
export function useCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
}
export function useCompaniesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    options,
  );
}
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesSuspenseQueryHookResult = ReturnType<typeof useCompaniesSuspenseQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyCompetencyMapsDocument = gql`
  query companyCompetencyMaps(
    $filter: ListCompetencyMapFilterInput
    $limit: Float!
    $page: Float!
  ) {
    competencyMaps(filter: $filter, page: $page, limit: $limit) {
      totalCount
      data {
        id
        createdAt
        isSample
        title
        specialization
        grades
        company {
          id
          shortName
        }
        competencesCount
        trialsCount
        deletedAt
      }
    }
  }
`;

/**
 * __useCompanyCompetencyMapsQuery__
 *
 * To run a query within a React component, call `useCompanyCompetencyMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCompetencyMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCompetencyMapsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanyCompetencyMapsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyCompetencyMapsQuery,
    CompanyCompetencyMapsQueryVariables
  > &
    ({ variables: CompanyCompetencyMapsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyCompetencyMapsQuery, CompanyCompetencyMapsQueryVariables>(
    CompanyCompetencyMapsDocument,
    options,
  );
}
export function useCompanyCompetencyMapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCompetencyMapsQuery,
    CompanyCompetencyMapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyCompetencyMapsQuery, CompanyCompetencyMapsQueryVariables>(
    CompanyCompetencyMapsDocument,
    options,
  );
}
export function useCompanyCompetencyMapsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyCompetencyMapsQuery,
        CompanyCompetencyMapsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyCompetencyMapsQuery, CompanyCompetencyMapsQueryVariables>(
    CompanyCompetencyMapsDocument,
    options,
  );
}
export type CompanyCompetencyMapsQueryHookResult = ReturnType<typeof useCompanyCompetencyMapsQuery>;
export type CompanyCompetencyMapsLazyQueryHookResult = ReturnType<
  typeof useCompanyCompetencyMapsLazyQuery
>;
export type CompanyCompetencyMapsSuspenseQueryHookResult = ReturnType<
  typeof useCompanyCompetencyMapsSuspenseQuery
>;
export type CompanyCompetencyMapsQueryResult = Apollo.QueryResult<
  CompanyCompetencyMapsQuery,
  CompanyCompetencyMapsQueryVariables
>;
export const CompanyDataDocument = gql`
  query companyData($companyId: ID!) {
    company(companyId: $companyId) {
      id
      inn
      fullName
      shortName
      kpp
      ogrn
      ogrnDate
      type
      managerFio
      managerPosition
      okpo
      okato
      oktmo
      registrationAddress
      state
      registrationDate
    }
  }
`;

/**
 * __useCompanyDataQuery__
 *
 * To run a query within a React component, call `useCompanyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyDataQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyDataQuery, CompanyDataQueryVariables> &
    ({ variables: CompanyDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyDataQuery, CompanyDataQueryVariables>(CompanyDataDocument, options);
}
export function useCompanyDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyDataQuery, CompanyDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyDataQuery, CompanyDataQueryVariables>(
    CompanyDataDocument,
    options,
  );
}
export function useCompanyDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyDataQuery, CompanyDataQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyDataQuery, CompanyDataQueryVariables>(
    CompanyDataDocument,
    options,
  );
}
export type CompanyDataQueryHookResult = ReturnType<typeof useCompanyDataQuery>;
export type CompanyDataLazyQueryHookResult = ReturnType<typeof useCompanyDataLazyQuery>;
export type CompanyDataSuspenseQueryHookResult = ReturnType<typeof useCompanyDataSuspenseQuery>;
export type CompanyDataQueryResult = Apollo.QueryResult<
  CompanyDataQuery,
  CompanyDataQueryVariables
>;
export const CompanyHeaderDocument = gql`
  query companyHeader($companyId: ID!) {
    company(companyId: $companyId) {
      id
      shortName
      identification
      status
    }
  }
`;

/**
 * __useCompanyHeaderQuery__
 *
 * To run a query within a React component, call `useCompanyHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHeaderQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyHeaderQuery, CompanyHeaderQueryVariables> &
    ({ variables: CompanyHeaderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyHeaderQuery, CompanyHeaderQueryVariables>(
    CompanyHeaderDocument,
    options,
  );
}
export function useCompanyHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyHeaderQuery, CompanyHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyHeaderQuery, CompanyHeaderQueryVariables>(
    CompanyHeaderDocument,
    options,
  );
}
export function useCompanyHeaderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyHeaderQuery, CompanyHeaderQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyHeaderQuery, CompanyHeaderQueryVariables>(
    CompanyHeaderDocument,
    options,
  );
}
export type CompanyHeaderQueryHookResult = ReturnType<typeof useCompanyHeaderQuery>;
export type CompanyHeaderLazyQueryHookResult = ReturnType<typeof useCompanyHeaderLazyQuery>;
export type CompanyHeaderSuspenseQueryHookResult = ReturnType<typeof useCompanyHeaderSuspenseQuery>;
export type CompanyHeaderQueryResult = Apollo.QueryResult<
  CompanyHeaderQuery,
  CompanyHeaderQueryVariables
>;
export const CompanyOptionsDocument = gql`
  query companyOptions(
    $filter: ListCompanyFilterInput = {}
    $limit: Float! = 10
    $page: Float! = 0
  ) {
    companies(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        shortName
        inn
      }
    }
  }
`;

/**
 * __useCompanyOptionsQuery__
 *
 * To run a query within a React component, call `useCompanyOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanyOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyOptionsQuery, CompanyOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyOptionsQuery, CompanyOptionsQueryVariables>(
    CompanyOptionsDocument,
    options,
  );
}
export function useCompanyOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyOptionsQuery, CompanyOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyOptionsQuery, CompanyOptionsQueryVariables>(
    CompanyOptionsDocument,
    options,
  );
}
export function useCompanyOptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyOptionsQuery, CompanyOptionsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyOptionsQuery, CompanyOptionsQueryVariables>(
    CompanyOptionsDocument,
    options,
  );
}
export type CompanyOptionsQueryHookResult = ReturnType<typeof useCompanyOptionsQuery>;
export type CompanyOptionsLazyQueryHookResult = ReturnType<typeof useCompanyOptionsLazyQuery>;
export type CompanyOptionsSuspenseQueryHookResult = ReturnType<
  typeof useCompanyOptionsSuspenseQuery
>;
export type CompanyOptionsQueryResult = Apollo.QueryResult<
  CompanyOptionsQuery,
  CompanyOptionsQueryVariables
>;
export const CompanyTrialsDocument = gql`
  query companyTrials($filter: ListTrialFilterInput, $limit: Float!, $page: Float!) {
    trials(filter: $filter, page: $page, limit: $limit) {
      totalCount
      data {
        id
        createdAt
        completedAt
        examination {
          id
          competencyMap {
            id
            title
          }
        }
        companyCandidate {
          id
          fullName
          candidateUsername {
            id
            telegramUsername
          }
        }
        status
        recruiterReportFiles {
          id
          language
          file {
            id
            url
          }
        }
      }
    }
  }
`;

/**
 * __useCompanyTrialsQuery__
 *
 * To run a query within a React component, call `useCompanyTrialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTrialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTrialsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanyTrialsQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyTrialsQuery, CompanyTrialsQueryVariables> &
    ({ variables: CompanyTrialsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyTrialsQuery, CompanyTrialsQueryVariables>(
    CompanyTrialsDocument,
    options,
  );
}
export function useCompanyTrialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyTrialsQuery, CompanyTrialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyTrialsQuery, CompanyTrialsQueryVariables>(
    CompanyTrialsDocument,
    options,
  );
}
export function useCompanyTrialsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyTrialsQuery, CompanyTrialsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyTrialsQuery, CompanyTrialsQueryVariables>(
    CompanyTrialsDocument,
    options,
  );
}
export type CompanyTrialsQueryHookResult = ReturnType<typeof useCompanyTrialsQuery>;
export type CompanyTrialsLazyQueryHookResult = ReturnType<typeof useCompanyTrialsLazyQuery>;
export type CompanyTrialsSuspenseQueryHookResult = ReturnType<typeof useCompanyTrialsSuspenseQuery>;
export type CompanyTrialsQueryResult = Apollo.QueryResult<
  CompanyTrialsQuery,
  CompanyTrialsQueryVariables
>;
export const CompanyUsersDocument = gql`
  query companyUsers($filter: ListCompanyUserFilterInput, $limit: Float!, $page: Float!) {
    companyUsers(filter: $filter, page: $page, limit: $limit) {
      totalCount
      data {
        id
        userEmail {
          id
          email
          user {
            id
            firstName
            lastName
            middleName
          }
        }
        position
        isSupervisor
        isCreator
      }
    }
  }
`;

/**
 * __useCompanyUsersQuery__
 *
 * To run a query within a React component, call `useCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanyUsersQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables> &
    ({ variables: CompanyUsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    options,
  );
}
export function useCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    options,
  );
}
export function useCompanyUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    options,
  );
}
export type CompanyUsersQueryHookResult = ReturnType<typeof useCompanyUsersQuery>;
export type CompanyUsersLazyQueryHookResult = ReturnType<typeof useCompanyUsersLazyQuery>;
export type CompanyUsersSuspenseQueryHookResult = ReturnType<typeof useCompanyUsersSuspenseQuery>;
export type CompanyUsersQueryResult = Apollo.QueryResult<
  CompanyUsersQuery,
  CompanyUsersQueryVariables
>;
export const CopyCompetencyMapDocument = gql`
  mutation copyCompetencyMap($input: CopyCompetenceMapInputDto!) {
    copyCompetencyMap(input: $input) {
      id
    }
  }
`;
export type CopyCompetencyMapMutationFn = Apollo.MutationFunction<
  CopyCompetencyMapMutation,
  CopyCompetencyMapMutationVariables
>;

/**
 * __useCopyCompetencyMapMutation__
 *
 * To run a mutation, you first call `useCopyCompetencyMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCompetencyMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCompetencyMapMutation, { data, loading, error }] = useCopyCompetencyMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyCompetencyMapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyCompetencyMapMutation,
    CopyCompetencyMapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyCompetencyMapMutation, CopyCompetencyMapMutationVariables>(
    CopyCompetencyMapDocument,
    options,
  );
}
export type CopyCompetencyMapMutationHookResult = ReturnType<typeof useCopyCompetencyMapMutation>;
export type CopyCompetencyMapMutationResult = Apollo.MutationResult<CopyCompetencyMapMutation>;
export type CopyCompetencyMapMutationOptions = Apollo.BaseMutationOptions<
  CopyCompetencyMapMutation,
  CopyCompetencyMapMutationVariables
>;
export const CreateCompetencyMapFromFileDocument = gql`
  mutation createCompetencyMapFromFile($companyId: ID!, $input: CreateCompetencyMapFromFileInput!) {
    createCompetencyMapFromFile(companyId: $companyId, input: $input) {
      id
    }
  }
`;
export type CreateCompetencyMapFromFileMutationFn = Apollo.MutationFunction<
  CreateCompetencyMapFromFileMutation,
  CreateCompetencyMapFromFileMutationVariables
>;

/**
 * __useCreateCompetencyMapFromFileMutation__
 *
 * To run a mutation, you first call `useCreateCompetencyMapFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompetencyMapFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompetencyMapFromFileMutation, { data, loading, error }] = useCreateCompetencyMapFromFileMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompetencyMapFromFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompetencyMapFromFileMutation,
    CreateCompetencyMapFromFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompetencyMapFromFileMutation,
    CreateCompetencyMapFromFileMutationVariables
  >(CreateCompetencyMapFromFileDocument, options);
}
export type CreateCompetencyMapFromFileMutationHookResult = ReturnType<
  typeof useCreateCompetencyMapFromFileMutation
>;
export type CreateCompetencyMapFromFileMutationResult =
  Apollo.MutationResult<CreateCompetencyMapFromFileMutation>;
export type CreateCompetencyMapFromFileMutationOptions = Apollo.BaseMutationOptions<
  CreateCompetencyMapFromFileMutation,
  CreateCompetencyMapFromFileMutationVariables
>;
export const DeleteCompetencyMapDocument = gql`
  mutation deleteCompetencyMap($competencyMapId: ID!) {
    deleteCompetencyMap(competencyMapId: $competencyMapId)
  }
`;
export type DeleteCompetencyMapMutationFn = Apollo.MutationFunction<
  DeleteCompetencyMapMutation,
  DeleteCompetencyMapMutationVariables
>;

/**
 * __useDeleteCompetencyMapMutation__
 *
 * To run a mutation, you first call `useDeleteCompetencyMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetencyMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetencyMapMutation, { data, loading, error }] = useDeleteCompetencyMapMutation({
 *   variables: {
 *      competencyMapId: // value for 'competencyMapId'
 *   },
 * });
 */
export function useDeleteCompetencyMapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompetencyMapMutation,
    DeleteCompetencyMapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompetencyMapMutation, DeleteCompetencyMapMutationVariables>(
    DeleteCompetencyMapDocument,
    options,
  );
}
export type DeleteCompetencyMapMutationHookResult = ReturnType<
  typeof useDeleteCompetencyMapMutation
>;
export type DeleteCompetencyMapMutationResult = Apollo.MutationResult<DeleteCompetencyMapMutation>;
export type DeleteCompetencyMapMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompetencyMapMutation,
  DeleteCompetencyMapMutationVariables
>;
export const RestoreCompetencyMapDocument = gql`
  mutation restoreCompetencyMap($competencyMapId: ID!) {
    restoreCompetencyMap(competencyMapId: $competencyMapId)
  }
`;
export type RestoreCompetencyMapMutationFn = Apollo.MutationFunction<
  RestoreCompetencyMapMutation,
  RestoreCompetencyMapMutationVariables
>;

/**
 * __useRestoreCompetencyMapMutation__
 *
 * To run a mutation, you first call `useRestoreCompetencyMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCompetencyMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCompetencyMapMutation, { data, loading, error }] = useRestoreCompetencyMapMutation({
 *   variables: {
 *      competencyMapId: // value for 'competencyMapId'
 *   },
 * });
 */
export function useRestoreCompetencyMapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreCompetencyMapMutation,
    RestoreCompetencyMapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreCompetencyMapMutation, RestoreCompetencyMapMutationVariables>(
    RestoreCompetencyMapDocument,
    options,
  );
}
export type RestoreCompetencyMapMutationHookResult = ReturnType<
  typeof useRestoreCompetencyMapMutation
>;
export type RestoreCompetencyMapMutationResult =
  Apollo.MutationResult<RestoreCompetencyMapMutation>;
export type RestoreCompetencyMapMutationOptions = Apollo.BaseMutationOptions<
  RestoreCompetencyMapMutation,
  RestoreCompetencyMapMutationVariables
>;
export const SwitchIsSampleCompetencyMapDocument = gql`
  mutation switchIsSampleCompetencyMap($competencyMapId: ID!) {
    switchIsSampleCompetencyMap(competencyMapId: $competencyMapId) {
      id
      isSample
    }
  }
`;
export type SwitchIsSampleCompetencyMapMutationFn = Apollo.MutationFunction<
  SwitchIsSampleCompetencyMapMutation,
  SwitchIsSampleCompetencyMapMutationVariables
>;

/**
 * __useSwitchIsSampleCompetencyMapMutation__
 *
 * To run a mutation, you first call `useSwitchIsSampleCompetencyMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchIsSampleCompetencyMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchIsSampleCompetencyMapMutation, { data, loading, error }] = useSwitchIsSampleCompetencyMapMutation({
 *   variables: {
 *      competencyMapId: // value for 'competencyMapId'
 *   },
 * });
 */
export function useSwitchIsSampleCompetencyMapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchIsSampleCompetencyMapMutation,
    SwitchIsSampleCompetencyMapMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchIsSampleCompetencyMapMutation,
    SwitchIsSampleCompetencyMapMutationVariables
  >(SwitchIsSampleCompetencyMapDocument, options);
}
export type SwitchIsSampleCompetencyMapMutationHookResult = ReturnType<
  typeof useSwitchIsSampleCompetencyMapMutation
>;
export type SwitchIsSampleCompetencyMapMutationResult =
  Apollo.MutationResult<SwitchIsSampleCompetencyMapMutation>;
export type SwitchIsSampleCompetencyMapMutationOptions = Apollo.BaseMutationOptions<
  SwitchIsSampleCompetencyMapMutation,
  SwitchIsSampleCompetencyMapMutationVariables
>;
export const CompetencyMapListDocument = gql`
  query competencyMapList(
    $filter: ListCompetencyMapFilterInput = {}
    $limit: Float!
    $page: Float!
  ) {
    competencyMaps(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        deletedAt
        createdAt
        company {
          id
          shortName
        }
        isSample
        isDeleted
        title
        specialization
        grades
        competencesCount
        trialsCount
      }
    }
  }
`;

/**
 * __useCompetencyMapListQuery__
 *
 * To run a query within a React component, call `useCompetencyMapListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetencyMapListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetencyMapListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompetencyMapListQuery(
  baseOptions: Apollo.QueryHookOptions<CompetencyMapListQuery, CompetencyMapListQueryVariables> &
    ({ variables: CompetencyMapListQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompetencyMapListQuery, CompetencyMapListQueryVariables>(
    CompetencyMapListDocument,
    options,
  );
}
export function useCompetencyMapListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompetencyMapListQuery,
    CompetencyMapListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompetencyMapListQuery, CompetencyMapListQueryVariables>(
    CompetencyMapListDocument,
    options,
  );
}
export function useCompetencyMapListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompetencyMapListQuery, CompetencyMapListQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompetencyMapListQuery, CompetencyMapListQueryVariables>(
    CompetencyMapListDocument,
    options,
  );
}
export type CompetencyMapListQueryHookResult = ReturnType<typeof useCompetencyMapListQuery>;
export type CompetencyMapListLazyQueryHookResult = ReturnType<typeof useCompetencyMapListLazyQuery>;
export type CompetencyMapListSuspenseQueryHookResult = ReturnType<
  typeof useCompetencyMapListSuspenseQuery
>;
export type CompetencyMapListQueryResult = Apollo.QueryResult<
  CompetencyMapListQuery,
  CompetencyMapListQueryVariables
>;
export const BlockUnblockUserDocument = gql`
  mutation blockUnblockUser($userId: ID!) {
    blockUnblockUser(userId: $userId) {
      isBlocked
      id
    }
  }
`;
export type BlockUnblockUserMutationFn = Apollo.MutationFunction<
  BlockUnblockUserMutation,
  BlockUnblockUserMutationVariables
>;

/**
 * __useBlockUnblockUserMutation__
 *
 * To run a mutation, you first call `useBlockUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUnblockUserMutation, { data, loading, error }] = useBlockUnblockUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBlockUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUnblockUserMutation,
    BlockUnblockUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockUnblockUserMutation, BlockUnblockUserMutationVariables>(
    BlockUnblockUserDocument,
    options,
  );
}
export type BlockUnblockUserMutationHookResult = ReturnType<typeof useBlockUnblockUserMutation>;
export type BlockUnblockUserMutationResult = Apollo.MutationResult<BlockUnblockUserMutation>;
export type BlockUnblockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUnblockUserMutation,
  BlockUnblockUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      middleName
      userEmail {
        id
        email
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserCompaniesDocument = gql`
  query userCompanies($filter: ListCompanyUserFilterInput, $limit: Float!, $page: Float!) {
    companyUsers(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        createdAt
        company {
          id
          inn
          shortName
        }
        position
        isCreator
        isSupervisor
      }
    }
  }
`;

/**
 * __useUserCompaniesQuery__
 *
 * To run a query within a React component, call `useUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables> &
    ({ variables: UserCompaniesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(
    UserCompaniesDocument,
    options,
  );
}
export function useUserCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(
    UserCompaniesDocument,
    options,
  );
}
export function useUserCompaniesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserCompaniesQuery, UserCompaniesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserCompaniesQuery, UserCompaniesQueryVariables>(
    UserCompaniesDocument,
    options,
  );
}
export type UserCompaniesQueryHookResult = ReturnType<typeof useUserCompaniesQuery>;
export type UserCompaniesLazyQueryHookResult = ReturnType<typeof useUserCompaniesLazyQuery>;
export type UserCompaniesSuspenseQueryHookResult = ReturnType<typeof useUserCompaniesSuspenseQuery>;
export type UserCompaniesQueryResult = Apollo.QueryResult<
  UserCompaniesQuery,
  UserCompaniesQueryVariables
>;
export const UserDataDocument = gql`
  query userData($userId: ID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
      middleName
      userEmail {
        id
        email
      }
    }
  }
`;

/**
 * __useUserDataQuery__
 *
 * To run a query within a React component, call `useUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDataQuery(
  baseOptions: Apollo.QueryHookOptions<UserDataQuery, UserDataQueryVariables> &
    ({ variables: UserDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, options);
}
export function useUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserDataQuery, UserDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, options);
}
export function useUserDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserDataQuery, UserDataQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, options);
}
export type UserDataQueryHookResult = ReturnType<typeof useUserDataQuery>;
export type UserDataLazyQueryHookResult = ReturnType<typeof useUserDataLazyQuery>;
export type UserDataSuspenseQueryHookResult = ReturnType<typeof useUserDataSuspenseQuery>;
export type UserDataQueryResult = Apollo.QueryResult<UserDataQuery, UserDataQueryVariables>;
export const UserHeaderDocument = gql`
  query userHeader($userId: ID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
      middleName
      isBlocked
    }
  }
`;

/**
 * __useUserHeaderQuery__
 *
 * To run a query within a React component, call `useUserHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHeaderQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<UserHeaderQuery, UserHeaderQueryVariables> &
    ({ variables: UserHeaderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHeaderQuery, UserHeaderQueryVariables>(UserHeaderDocument, options);
}
export function useUserHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserHeaderQuery, UserHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHeaderQuery, UserHeaderQueryVariables>(
    UserHeaderDocument,
    options,
  );
}
export function useUserHeaderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserHeaderQuery, UserHeaderQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserHeaderQuery, UserHeaderQueryVariables>(
    UserHeaderDocument,
    options,
  );
}
export type UserHeaderQueryHookResult = ReturnType<typeof useUserHeaderQuery>;
export type UserHeaderLazyQueryHookResult = ReturnType<typeof useUserHeaderLazyQuery>;
export type UserHeaderSuspenseQueryHookResult = ReturnType<typeof useUserHeaderSuspenseQuery>;
export type UserHeaderQueryResult = Apollo.QueryResult<UserHeaderQuery, UserHeaderQueryVariables>;
export const UsersDocument = gql`
  query users($filter: ListUsersFilterInput! = {}, $limit: Float!, $page: Float!) {
    users(filter: $filter, limit: $limit, page: $page) {
      totalCount
      data {
        id
        createdAt
        firstName
        lastName
        middleName
        userEmail {
          id
          email
        }
        isBlocked
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables> &
    ({ variables: UsersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
