import { TableColumn } from 'layout/TableLayout/types.d';
import dayjs from 'dayjs';
import CopyMapButton from 'components/competencyMap/copyCompetencyMap/CopyMapButton';
import DeleteMapButton from 'components/competencyMap/DeleteMapButton';
import { CompetencyMap } from 'pages/Company/View/tabs/CompetencyMapList/index';
import SampleMapButton from 'components/competencyMap/SampleMapButton';
import { Stack } from '@mui/material';
import {
  CompanyCompetencyMapsQuery,
  Exact,
  InputMaybe,
  ListCompetencyMapFilterInput,
  Scalars,
} from '@generated/graphql';
import { ApolloQueryResult } from '@apollo/client';

type Refetch = (
  variables?:
    | Partial<
        Exact<{
          filter?: InputMaybe<ListCompetencyMapFilterInput>;
          limit: Scalars['Float']['input'];
          page: Scalars['Float']['input'];
        }>
      >
    | undefined,
) => Promise<ApolloQueryResult<CompanyCompetencyMapsQuery>>;

const COMPETENCY_MAP_COLUMNS = (refetch: Refetch): TableColumn<CompetencyMap>[] => [
  {
    title: 'Дата создания',
    name: 'createdAt',
    render: (map) => dayjs(map.createdAt).format('DD.MM.YYYY'),
  },
  {
    title: 'Шаблон',
    name: 'isTemplate',
    render: ({ isSample, id }) => <SampleMapButton isSample={isSample} competencyMapId={id} />,
  },
  {
    title: 'Название',
    name: 'title',
    render: (map) => map.title,
  },
  {
    title: 'Специализация',
    name: 'specialization',
    render: (map) => map.specialization,
  },
  {
    title: 'Грейд',
    name: 'grade',
    render: ({ grades }) => grades?.join(', ') || '-',
  },
  {
    title: 'Количество компетенций',
    name: 'knowledgeBlocks',
    render: ({ competencesCount }) => competencesCount,
  },
  {
    title: 'Количество тестирований',
    name: 'testCount',
    render: ({ trialsCount }) => trialsCount,
  },
  {
    title: 'Удаление',
    name: 'isDeleted',
    render: ({ deletedAt, id, title }) => (
      <DeleteMapButton isDeleted={!!deletedAt} refetch={refetch} {...{ id, title }} />
    ),
  },
  {
    title: 'Действия',
    name: 'actions',
    render: ({ id, title, company }) => (
      <Stack direction='row'>
        <CopyMapButton id={id} title={title} refetchCompetencyMapList={refetch} company={company} />
        {/* <ViewMapButton id={id} /> */}
      </Stack>
    ),
  },
];

export default COMPETENCY_MAP_COLUMNS;
