import { Language } from '@generated/graphql';
import { PopoverProps } from '@mui/material';
import LanguagePopover from 'components/common/LanguagePopover';
import { ComponentType, MouseEvent, useState } from 'react';

type WithLanguageProps<T> = T & {
  setLanguage: (language: Language) => void;
  popoverProps?: Pick<PopoverProps, 'anchorOrigin' | 'slotProps'> | Record<string, never>;
};

const withLanguagePopover = <T,>(Component: ComponentType<T>) => {
  // eslint-disable-next-line react/function-component-definition, react/display-name
  return (props: WithLanguageProps<T>) => {
    const { setLanguage, popoverProps } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (e: MouseEvent<HTMLElement>) => {
      setAnchorEl(e.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            handlePopoverOpen(e);
          }}
        />
        <LanguagePopover
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopoverClose}
          setLanguage={setLanguage}
          popoverProps={popoverProps}
        />
      </>
    );
  };
};

export default withLanguagePopover;
