import { TableColumn } from 'layout/TableLayout/types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import DeleteMapButton from 'components/competencyMap/DeleteMapButton';
import CopyMapButton from 'components/competencyMap/copyCompetencyMap/CopyMapButton';
import ROUTES from 'constants/routes';
import { Stack } from '@mui/material';
// import ViewMapButton from 'pages/CompetencyMap/list/ViewMapButton';
import SampleMapButton from 'components/competencyMap/SampleMapButton';
import { CompetencyMapData, Refetch } from 'pages/CompetencyMap/list/types';

const COMPETENCY_MAP_TABLE = (refetch: Refetch): TableColumn<CompetencyMapData>[] => [
  {
    title: 'Дата создания',
    render: (data) => dayjs(data.createdAt).format('DD.MM.YYYY'),
    name: 'createdAt',
  },
  {
    title: 'Компания',
    render: (data) => (
      <Link to={`/${ROUTES.COMPANIES}/${data.company.id}`}>{data.company.shortName}</Link>
    ),
    name: 'companyShortName',
  },
  {
    title: 'Шаблон',
    name: 'isTemplate',
    render: ({ isSample, id }) => <SampleMapButton isSample={isSample} competencyMapId={id} />,
  },
  {
    title: 'Название',
    name: 'title',
    render: (map) => map.title,
  },
  {
    title: 'Специализация',
    name: 'specialization',
    render: (map) => map.specialization,
  },
  {
    title: 'Грейд',
    name: 'grade',
    render: ({ grades }) => grades?.join(', ') || '-',
  },
  {
    title: 'Количество компетенций',
    name: 'knowledgeBlocks',
    render: ({ competencesCount }) => competencesCount,
  },
  {
    title: 'Количество тестирований',
    name: 'testCount',
    render: ({ trialsCount }) => trialsCount,
  },
  {
    title: 'Удаление',
    name: 'isDeleted',
    render: ({ deletedAt, id, title }) => (
      <DeleteMapButton isDeleted={!!deletedAt} {...{ id, title, refetch }} />
    ),
  },
  {
    title: 'Действия',
    name: 'actions',
    render: ({ id, title, company }) => (
      <Stack direction='row'>
        <CopyMapButton id={id} title={title} refetchCompetencyMapList={refetch} company={company} />
        {/* <ViewMapButton id={id} /> */}
      </Stack>
    ),
  },
];

export default COMPETENCY_MAP_TABLE;
