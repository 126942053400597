import { Box, IconButton, Popover, PopoverPaper, PopoverProps, Typography } from '@mui/material';
import RuFlag from 'assets/images/ru.svg';
import EnFlag from 'assets/images/en.svg';
import KzFlag from 'assets/images/kz.svg';
import { Language } from '@generated/graphql';
import styled from '@emotion/styled';

const StyledPopoverPaper = styled(PopoverPaper)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 4,
  padding: 8,
  borderRadius: 8,
  position: 'absolute',
  overflow: 'visible',
  '&:before': {
    content: "''",
    position: 'absolute',
    width: 16,
    height: 16,
    borderRadius: 2,
    backgroundColor: '#fff',
    top: -8,
    left: '50%',
    transform: 'translateX(-50%) rotateZ(45deg)',
  },
});

const LANGUAGE_ITEMS = [
  {
    label: 'RU',
    src: RuFlag,
    value: Language.Ru,
  },
  {
    label: 'EN',
    src: EnFlag,
    value: Language.En,
  },
  {
    label: 'KZ',
    src: KzFlag,
    value: Language.Kk,
  },
];

interface ILanguagePopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  setLanguage: (language: Language) => void;
  popoverProps?: Pick<PopoverProps, 'anchorOrigin' | 'slotProps'> | Record<string, never>;
}

const LanguagePopover = ({
  open,
  anchorEl,
  onClose,
  setLanguage,
  popoverProps = {},
}: ILanguagePopoverProps) => {
  const handleLangButtonClick = (language: Language) => {
    setLanguage(language);
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
        ...popoverProps.anchorOrigin,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={(e: MouseEvent) => {
        e.stopPropagation();
        onClose();
      }}
      slots={{ paper: StyledPopoverPaper }}
      slotProps={popoverProps.slotProps}
      disableRestoreFocus>
      {LANGUAGE_ITEMS.map((item) => (
        <IconButton
          key={`language-button-${item.value}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            padding: '6px 10px',
            borderRadius: 1,
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleLangButtonClick(item.value);
          }}>
          <Box src={item.src} component='img' alt={item.label} sx={{ height: 15, width: 21 }} />
          <Typography>{item.label}</Typography>
        </IconButton>
      ))}
    </Popover>
  );
};

export default LanguagePopover;
