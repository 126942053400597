import { IconButton, Stack, Tooltip } from '@mui/material';
import { Download } from '@mui/icons-material';
import { CompanyTrialsQuery, Language, Trial_Status } from '@generated/graphql';
import CircularLoading from 'components/common/CircularLoading';
import withLanguagePopover from 'hocs/withLanguagePopover';
import useGenerateTrialFile from 'hooks/useGenerateTrialFile';

const IconButtonWithLanguage = withLanguagePopover(IconButton);

interface DownloadReportsActionProps {
  trial: NonNullable<CompanyTrialsQuery['trials']['data']>[number];
}

const DownloadReportsAction = ({ trial }: DownloadReportsActionProps) => {
  const isCompleted = trial.status === Trial_Status.Completed;
  const { onGenerateTrialFile, loading } = useGenerateTrialFile();

  const onDownloadReport = async (language: Language) => {
    const reportFiles = trial?.recruiterReportFiles;
    if (trial) {
      const existingReport = reportFiles?.find((r) => r.language === language);
      if (existingReport) {
        window.open(existingReport.file.url);
      } else {
        await onGenerateTrialFile(language, trial.id);
      }
    }
  };

  if (!isCompleted) return null;
  return (
    <Stack direction='row' spacing={2}>
      <IconButtonWithLanguage setLanguage={onDownloadReport}>
        <Tooltip title='Скачать отчет'>
          {loading ? <CircularLoading sx={{ p: 0 }} size={20} /> : <Download />}
        </Tooltip>
      </IconButtonWithLanguage>
    </Stack>
  );
};

export default DownloadReportsAction;
